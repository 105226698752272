@import "../../scss/main.scss";

.wrapper {
  display: flex;

  gap: 15px;

  & .contentContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & p {
      @include text_small_bold;
    }
    & span {
      @include text_small_regular;
      color: $grayLight;
    }

    & .calorie {
      margin-top: auto;
      margin-bottom: 5px;
      @include text_small_bold;
      color: $green;
    }
  }
}
