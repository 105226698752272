@import "../../scss/main.scss";

.wrapper {
  & .dateHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    & .dateHeaderButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 100px;
      background-color: $grayVeryLight;
      cursor: pointer;

      & .leftArrow {
        stroke: $black;
        transform: rotate(90deg);
      }

      & .rightArrow {
        stroke: $black;
        transform: rotate(-90deg);
      }
    }

    & .dateTitle {
      @include text_small_medium;
      flex: 1;
      text-align: center;
    }
  }

  & .weekDaysNamesContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    gap: 10px;

    & .weekName {
      @include text_small_bold;
      color: $grayDark;
      text-align: center;
    }
  }

  & .weekContainer {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    flex-wrap: wrap;
    row-gap: 5px;

    & .weekRowContainer {
      display: flex;
      gap: 10px;
      position: relative;

      & .weekBackground {
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;

        background: $grayVeryLight;
        z-index: -1;
        border-radius: 70px;
      }
    }
  }

  & .dayWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    & .dayStreakContainer {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      & .day {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include text_small_bold;

        & .green {
          width: 6px;
          height: 6px;
          background: $green;
          position: absolute;
          bottom: 4px;
          border-radius: 100px;
        }
      }
    }

    & .inactiveDay {
      color: $grayMedium;
    }

    & .today {
      border-radius: 100px;
      background-color: $primary;
      color: $white;
    }
  }
}
