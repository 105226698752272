@import "../../scss/main.scss";

.wrapper {
  padding: 24px 24px 16px 24px;
  display: flex;
  flex-direction: row;
  border: 1px solid $shadow;
  border-radius: 20px;

  & .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-right: 40px;

    & .valuesContainer {
      margin-top: -7px;

      & p {
        @include text_small_bold;
        line-height: 16px;
        margin: 0;
        padding: 0;
      }
      & span {
        @include text_x_small_regular;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 14px;
        color: $grayLight;
      }
    }
  }
}
