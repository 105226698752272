@import "../../scss/main.scss";

.wrapper {
  & .title {
    @include desktop_h5;
  }
  & .infoTitle {
    @include text_small_regular;
    & span {
      @include text_small_bold;
    }
  }

  & .chartContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    & .rowContainer {
      display: flex;
      align-items: center;
      gap: 2px;
    }

    margin-top: 12px;
    padding: 24px;
    border: 1px solid $shadow;
    border-radius: 20px;

    & .headerValues {
      display: flex;
      justify-content: space-between;

      & .block {
        flex: 1;

        & .indicator {
          width: 8px;
          height: 8px;
          background: $primary;
          border-radius: 100px;
          border: 2px solid $white;
          display: inline-block;
          margin-right: 5px;
        }

        & .weight {
          @include mobile_h2;

          & span {
            @include mobile_h3;
          }
        }

        & .calories {
          @include text_medium_regular;
          margin-top: -5px;
        }

        & .title {
          @include text_x_small_regular;
          color: $grayLight;
          font-size: 10px;
          margin-top: -1px;
          text-transform: uppercase;
        }
      }
    }

    & .xAxisContainer {
      display: flex;
      justify-content: space-between;
      height: 14px;
      margin-top: -20px;
    }

    & .xAxisText {
      @include text_x_small_regular;
      color: $grayLight;
      text-align: center;
    }

    & .footerChart {
      display: flex;
      align-items: center;
      column-gap: 15px;
      row-gap: 5px;
      margin-top: 5px;
      flex-wrap: wrap;
    }
  }
}
