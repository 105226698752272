@import "../../scss/styles.scss";

.wrapper {
  -webkit-box-shadow: 0px 0px 30px 0px $shadow;
  -moz-box-shadow: 0px 0px 30px 0px $shadow;
  box-shadow: 0px 0px 30px 0px $shadow;
  border-radius: 15px;
  min-width: 315px;
  max-width: 315px;
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid $grayE430;

    & h6 {
      @include desktop_h5;
    }

    & .plusButton {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary;
      border-radius: 100px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        opacity: 0.98;
      }

      & svg {
        stroke: $white;
        width: 20px;
        height: 20px;
      }
    }
  }

  & .contentContainer {
    display: flex;
    flex-direction: column;
    min-height: 70vh;
    position: relative;

    & .emptyTitle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      @include text_small_regular;
      color: $grayAf;
    }
  }
}

.wrapperActive {
  background: $primaryLight;
}
