@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
  position: relative;

  & .title {
    @include desktop_h5;
  }

  & .updatedDate {
    @include text_small_regular;
    color: $grayLight;
    position: absolute;
    right: 0;
    top: 0;
  }

  & .infoTitle {
    @include text_small_regular;
    & span {
      @include text_small_bold;
    }
  }
}
