@import "../../scss/styles.scss";

.wrapper {
  background: $white;
  margin-bottom: 8px;
  & .accordingInfo {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    padding-right: 24px;
    border: 1px solid $shadow;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    background: $white;
    & .menuIcon {
      fill: $grayAf;
      margin-right: 12px;
      cursor: pointer;
    }

    & .selectIconButton {
      width: 56px;
      height: 56px;
      border: 1px solid $grayE4;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 16px;
      position: relative;
      border-radius: 100;

      & span {
        @include text_x_small_regular;
        font-size: 8px;
        color: $grayLight;
        text-transform: uppercase;
      }

      & .image {
        position: absolute;
        width: 56px;
        left: 0;
        height: 56px;
        bottom: 0;
        right: 0;
        top: 0;
        object-fit: cover;
        border-radius: 100%;
      }

      & .deleteIconContainer {
        width: 20px;
        height: 20px;
        background: $white;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -9px;
        left: 10%;
        transform: translate(-50%);

        & .deleteIcon {
          width: 16px;
          height: 16px;
        }
      }
    }

    & .title {
      @include text_medium_bold;
      flex: 1;
    }
    & .inputWrapper {
      flex: 1;
    }

    & .deleteIconContainer {
      width: 34px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 24px;
      margin-right: 12px;
      & .deleteIcon {
        min-width: 24px;
        height: 24px;
      }
    }

    & .arrowDownIconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 56px;
      cursor: pointer;

      & .arrowDownIcon {
        width: 12px;

        stroke: $black;
      }
    }

    & .arrowDownIconOpened {
      transform: rotate(180deg);
    }
  }
  & .accordingInfoOpened {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 0;
  }
}
