@import "../../scss/main.scss";

.wrapper {
  padding: 8px 16px 8px 20px;
  background: $grayVeryLight;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  position: relative;

  & span {
    @include text_small_bold;

    color: $dark;
  }

  & .arrow {
    width: 12px;
    height: 12px;
    stroke: $dark;
  }

  & .pickerContainer {
    padding: 24px;
    z-index: 9;
    background: $white;
    top: 50px;
    left: 0;
    width: 435px;
    position: absolute;
    border-radius: 20px;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.14);

    & .sectionTitle {
      margin-top: 22px;
      margin-bottom: 10px;
      @include text_x_small_regular;
      color: $grayLight;
    }

    & .monthsContainer {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;
      display: flex;
      & .month {
        cursor: pointer;
        width: 125px;
        height: 36px;
        border-radius: 8px;
        border: 1px solid $shadow;
        display: flex;
        align-items: center;
        justify-content: center;

        & span {
          @include text_small_bold;
          color: $primary;
        }
      }

      & .selectedMonth {
        background: $primary;

        & span {
          color: $white;
        }
      }
    }
  }
}
