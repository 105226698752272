@import "../../scss/main.scss";

.wrapper {
  padding: 8px 16px 8px 20px;
  background: $grayVeryLight;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  position: relative;

  & span {
    @include text_small_bold;

    color: $dark;
  }

  & .arrow {
    width: 12px;
    height: 12px;
    stroke: $dark;
  }

  & .pickerContainer {
    padding: 16px;
    z-index: 9;
    background: $white;
    top: 50px;
    left: 0;

    position: absolute;
    border-radius: 20px;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.14);
  }
}
