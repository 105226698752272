@import "../../scss/main.scss";

.wrapper {
  display: flex;
  gap: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 10px;
  align-items: center;
  border-bottom-left-radius: 15px;
  position: absolute;
  top: 0;
  right: 0;

  & span {
    @include text_x_small_bold;
    font-size: 10px;
    line-height: 16px;
  }
}
