@import "../../scss/main.scss";

.chartItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.chartItemDot {
  display: flex;
  flex-direction: column;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.chartItemDotRotate {
  display: flex;
  flex-direction: column;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(135deg);
}

.chartItemTitle {
  @include text_x_small_regular;
  color: $grayLight;
}
