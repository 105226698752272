@import "../scss/vars.scss";
@import "../scss/typoghraphy.scss";
@import "../scss/quill-editor.scss";
@import "../scss/quill-content.scss";

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}
a:focus {
  outline: none;
}

body {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 16px;
  color: $dark;
  background-color: $white;
}

.container {
  width: 100%;
  max-width: 1400px;
  padding: 0 15px;
  margin: 0 auto;
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 10;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: all 0.2s ease;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: all 0.2s ease;
}

.Toastify__toast-container {
  width: 320px;
}

.Toastify__toast {
  background-color: $white;
  color: $dark;
}
.Toastify__close-button {
  color: $dark;
}

@media (max-width: 480px) {
  .Toastify__toast-container--top-center {
    right: 10px;
    top: 10px;
  }
  .Toastify__toast-container {
    left: initial;
  }
}

.ql-snow {
  border-color: $shadow !important;
}

.ql-container {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.ql-editor {
  min-height: 200px;
}

.html-wrapper {
  & p {
    margin-bottom: 8px;
    font-size: 14px;
  }

  & h1 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 12px;
    font-weight: 600;
  }

  & h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
    font-weight: 600;
  }

  & h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    font-weight: 600;
  }

  & ul {
    margin-left: 18px;
    margin-bottom: 8px;
    font-size: 14px;
  }

  & ol {
    margin-left: 18px;
    margin-bottom: 8px;
    font-size: 14px;
  }

  & img {
    max-width: 100%;
    height: auto;
    margin: 8px 0 0 0;
  }

  & blockquote {
    border-left: 4px solid #ddd;
    padding: 4px 8px;
    background-color: #f9f9f9;
    margin-bottom: 8px;
    font-size: 14px;
  }

  & a {
    color: $primary;
    text-decoration: underline;
    font-size: 14px;
  }

  & strong {
    font-weight: 600;
  }

  & em {
    font-style: italic;
  }

  & u {
    text-decoration: underline;
  }

  /* React-Quill-specific styles */
  & .ql-bold {
    font-weight: 600;
  }

  & .ql-italic {
    font-style: italic;
  }

  & .ql-underline {
    text-decoration: underline;
  }

  & .ql-strike {
    text-decoration: line-through;
  }

  & .ql-align-center {
    text-align: center;
  }

  & .ql-align-right {
    text-align: right;
  }

  & .ql-size-small {
    font-size: 10px;
  }

  & .ql-size-large {
    font-size: 16px;
  }

  & .ql-size-huge {
    font-size: 20px;
  }

  & .ql-blockquote {
    border-left: 4px solid #aaa;
    padding: 8px;
    background-color: #f9f9f9;
    font-size: 14px;
  }
}

.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0;
  position: relative;

  &.ql-disabled .ql-tooltip {
    visibility: hidden;
  }

  &:not(.ql-disabled) {
    li[data-list="checked"] > .ql-ui,
    li[data-list="unchecked"] > .ql-ui {
      cursor: pointer;
    }
  }
}

.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;

  p {
    margin: 0;
    padding: 0;
  }
}

.ql-editor {
  box-sizing: border-box;
  counter-reset: list-0 list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
    list-9;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;

  > * {
    cursor: text;
  }

  p,
  ol,
  pre,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;

    @supports (counter-set: none) {
      counter-set: list-0 list-1 list-2 list-3 list-4 list-5 list-6 list-7
        list-8 list-9;
    }

    @supports not (counter-set: none) {
      counter-reset: list-0 list-1 list-2 list-3 list-4 list-5 list-6 list-7
        list-8 list-9;
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  td {
    border: 1px solid #000;
    padding: 2px 5px;
    outline: none;
  }

  ol {
    padding-left: 1.5em;
  }

  li {
    list-style-type: none;
    padding-left: 1.5em;
    position: relative;

    > .ql-ui:before {
      display: inline-block;
      margin-left: -1.5em;
      margin-right: 0.3em;
      text-align: right;
      white-space: nowrap;
      width: 1.2em;
    }

    &[data-list="checked"] > .ql-ui,
    &[data-list="unchecked"] > .ql-ui {
      color: #777;
    }

    &[data-list="bullet"] > .ql-ui:before {
      content: "\2022";
    }

    &[data-list="checked"] > .ql-ui:before {
      content: "\2611";
    }

    &[data-list="unchecked"] > .ql-ui:before {
      content: "\2610";
    }

    &[data-list="ordered"] {
      counter-increment: list-0;

      > .ql-ui:before {
        content: counter(list-0, decimal) ". ";
      }
    }

    // Додаткові відступи для нумерації
    &.ql-indent-1 {
      counter-increment: list-1;

      &[data-list="ordered"] > .ql-ui:before {
        content: counter(list-1, lower-alpha) ". ";
      }
    }

    &.ql-indent-2 {
      counter-increment: list-2;

      &[data-list="ordered"] > .ql-ui:before {
        content: counter(list-2, lower-roman) ". ";
      }
    }

    // Повторити для більш глибоких відступів, якщо потрібно
  }

  // Відступи для списків
  .ql-indent-1 {
    &:not(.ql-direction-rtl) {
      padding-left: 3em;
    }

    &.ql-direction-rtl.ql-align-right {
      padding-right: 3em;
    }

    li {
      &:not(.ql-direction-rtl) {
        padding-left: 4.5em;
      }

      &.ql-direction-rtl.ql-align-right {
        padding-right: 4.5em;
      }
    }
  }

  .ql-code-block-container {
    font-family: monospace;
  }

  .ql-video {
    display: block;
    max-width: 100%;

    &.ql-align-center {
      margin: 0 auto;
    }

    &.ql-align-right {
      margin: 0 0 0 auto;
    }
  }

  .ql-bg-black {
    background-color: #000;
  }

  .ql-bg-red {
    background-color: #e60000;
  }

  // Додаткові стилі для кольорів або інших елементів
}
