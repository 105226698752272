@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  overflow-y: auto;
}

.modal {
  max-height: 100vh;

  & .innerContainer {
    position: relative;
    width: 460px;
    margin-top: 50px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & .title {
      @include desktop_h5;
    }

    & .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      stroke: $dark;
      cursor: pointer;
    }

    & .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & .avatarBtn {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        background: #e2e4e7;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 22px;
        position: relative;

        & .avatar {
          position: absolute;
          border-radius: 100px;
          width: 100px;
          height: 100px;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          object-fit: cover;
        }
        & .deleteAvatarButton {
          border-radius: 100%;
          width: 26px;
          height: 26px;
          background: $redOpacity20;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: -5px;
          right: -10px;
        }
      }
      & .avatarTitle {
        margin-top: 10px;
        margin-bottom: 22px;
        @include text_x_small_regular;
        color: $deactive;
      }
      & .deleteTitle {
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 22px;
        @include text_x_small_regular;
        color: $red;
      }
    }

    & .inputsContainer {
      border-top: 1px solid $grayE4;
      padding: 22px 0px;
      display: flex;
      flex-direction: column;
      gap: 22px;
    }
  }
}
