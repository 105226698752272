@import "../../scss/styles.scss";

.tabs {
  display: flex;

  padding: 4px;
  background: $grayVeryLight;
  border-radius: 47px;
  margin-bottom: 30px;

  & .tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px;
    cursor: pointer;
    & span {
      @include text_medium_bold;
      color: $grayLight;
    }
    & svg {
      width: 24px;
      height: 24px;
      fill: $grayLight;
    }
  }

  & .selectedTab {
    padding: 10px;
    flex: 1;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50px;
    background: $white;
    box-shadow: 0px 2px 4px 0px #181e280d;

    & span {
      @include text_medium_bold;
      color: $primary;
    }
    & svg {
      width: 24px;
      height: 24px;
      fill: $primary;
    }
  }
}
