@import "../../scss/main.scss";

.wrapper {
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .title {
      @include text_large_bold;
    }

    & .details {
      display: flex;
      align-items: center;
      gap: 2px;
      cursor: pointer;

      & p {
        @include text_small_bold;
        color: $primary;
        transition: all 0.2s ease-in-out;

        &:hover {
          opacity: 0.7;
        }
      }
      & .rightArrow {
        transform: rotate(90deg);
        fill: $primary;
        width: 20px;
        height: 20px;

        transition: all 0.2s ease-in-out;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  & .headerValues {
    display: flex;
    justify-content: space-between;

    & .block {
      flex: 1;
      display: flex;
      flex-direction: column;

      & .weight {
        @include mobile_h2;
        height: 36px;

        & span {
          @include mobile_h3;
        }
      }

      & .calories {
        @include text_medium_regular;
        margin-top: -5px;
      }

      & .title {
        @include text_x_small_regular;
        color: $grayLight;
        font-size: 10px;
        margin-top: -1px;
        text-transform: uppercase;
      }
    }
  }

  & .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
  }

  & .chartContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    & .rowContainer {
      display: flex;
      align-items: center;
      gap: 2px;
    }

    margin-top: 12px;
    padding: 24px;
    border: 1px solid $shadow;
    border-radius: 20px;

    & .xAxisContainer {
      display: flex;
      justify-content: space-between;
      height: 14px;
      margin-top: -20px;
    }

    & .xAxisText {
      @include text_x_small_regular;
      color: $grayLight;
      text-align: center;
    }

    & .footerChart {
      display: flex;
      align-items: center;
      column-gap: 15px;
      row-gap: 5px;
      margin-top: 5px;
      flex-wrap: wrap;
    }
  }
}
