@import "../../scss/main.scss";

.wrapper {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 227, 173, 0.2);

  & svg {
    fill: $springGreen;
  }
}
