@import "../../../scss/main.scss";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 1520px;
  width: 100%;
}
.wrapper {
  padding-bottom: 30px;
  & .header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 42px;
    margin-left: -8px;

    & .title {
      @include desktop_h5;
      font-weight: 600;
    }

    & .headerArrowButton {
      cursor: pointer;
      width: 32px;
      height: 32px;
      stroke: $black;
    }

    & .headerAddButton {
      margin-left: auto;
      align-items: center;
      display: flex;
      gap: 12px;

      & .buttonDivider {
        width: 1px;
        height: 34px;
        background: $grayE4;
      }
    }
  }
  & .rowContainer {
    display: flex;
    gap: 30px;

    & .contentContainer {
      flex: 1;
      background: $white;
      border-radius: 24px;
      margin-top: 30px;
      padding: 30px;
      height: fit-content;

      -webkit-box-shadow: 0px 0px 30px 0px $shadow;
      -moz-box-shadow: 0px 0px 30px 0px $shadow;
      box-shadow: 0px 0px 30px 0px $shadow;

      & .accordionContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 25px;
      }

      & .actionButtons {
        display: flex;
        width: fit-content;
        border: 1px solid $shadow;
        border-radius: 18px;
        margin-top: 25px;

        & .actionButton {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 12px 16px;
          cursor: pointer;
          transition: all 0.2s linear;

          &:hover {
            opacity: 0.8;
          }

          &:not(:last-child) {
            border-right: 1px solid $shadow;
          }

          & svg {
            width: 24px;
            height: 24px;

            fill: $primary;
          }

          & span {
            @include text_small_bold;
            color: $primary;
          }
        }
      }
    }
  }
}
