@import "../../scss/styles.scss";

@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  overflow-y: auto;
}

.modal {
  max-height: 100vh;

  & .innerContainer {
    position: relative;
    width: 460px;
    margin-top: 50px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & .title {
      @include desktop_h5;
      margin-bottom: 22px;
    }

    & .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      stroke: $dark;
      cursor: pointer;
    }

    & .addContainer {
      margin-top: 22px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-bottom: 32px;
      cursor: pointer;
      & .addButton {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $grayE4;
        border-radius: 100px;
        cursor: pointer;
        object-fit: cover;

        & svg {
          width: 24px;
          height: 24px;
          stroke: $primary;
        }
      }

      & span {
        @include text_small_regular;
        font-family: $secondary-font;
        color: $grayLight;
      }

      & .changeIconContainer {
        display: flex;
        align-items: center;
        gap: 15px;

        & .changeIcon {
          display: flex;
          align-items: center;
          gap: 5px;

          & svg {
            width: 14px;
            height: 14px;
            fill: $grayLight;
          }
        }

        & .deleteIcon {
          display: flex;
          align-items: center;
          gap: 5px;

          & svg {
            width: 14px;
            height: 14px;
            fill: $grayLight;
          }

          & span {
            color: $red;
          }
        }
      }
    }
  }
}
