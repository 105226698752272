@import "../../scss/main.scss";

.wrapper {
  & h6 {
    @include desktop_h5;
  }

  & .activityContainer {
    margin-top: 10px;
  }

  & .activityDivider {
    height: 2px;
    background: $green;
  }
}
