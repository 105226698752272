@import "../../scss/main.scss";

.goalStep {
  width: 14px;
  height: 14px;
  border-radius: 100px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;

  & .tooltip {
    padding: 4px 10px;
    background: rgba(30, 38, 50, 1);
    border-radius: 10px;
    height: 56px;
    position: absolute;
    top: -70px;
    width: max-content;
    display: none;

    & p {
      @include text_x_small_regular;
      font-size: 10px;
      color: rgba(187, 201, 215, 1);
      text-align: center;
      line-height: 16px;
    }

    & .value {
      color: $white;
      font-weight: 600;
      & span {
        font-weight: 400;

        color: rgba(187, 201, 215, 1);
      }
    }
    & svg {
      position: absolute;
      bottom: -12px;
      transform: translateX(-50%);
      left: 50%;
      fill: rgba(30, 38, 50, 1);
    }
  }

  &:hover {
    & .tooltip {
      display: block;
    }
  }

  & .goalStepInner {
    transition: all 0.3s ease;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: $white;
  }

  &:hover {
    width: 20px;
    height: 20px;
    background: $white;

    & .goalStepInner {
      width: 12px;
      height: 12px;
      border-radius: 100px;
      background-color: $primary;
    }
  }
}

.wrapper {
  height: 24px;
  border-radius: 24px;
  background-color: $grayVeryLight;
  position: relative;
  overflow: hidden;
  flex: 1;

  & .tooltipContainer {
    top: -70px;
    position: absolute;
    left: 0;
    right: 0;

    & .tooltip {
      transform: translateX(-50%);
    }
  }
  & .progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transition: all 0.3s ease;
    background-color: $green;
    border-radius: 24px;
  }
}
