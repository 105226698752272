@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  & .leftArrow {
    transform: rotate(90deg);
  }

  & .rightArrow {
    transform: rotate(-90deg);
  }

  & .todayButton {
    width: 80px;
    border-radius: 100px;
    border: 1px solid $shadow !important;
    background-color: white;
    height: 36px;
  }
}
