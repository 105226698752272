@import "../../scss/main.scss";

.wrapper {
  padding: 4px 2px;
  background: $grayVeryLight;
  border-radius: 20px;

  & .header {
    padding: 4px 10px;
    display: flex;
    gap: 20px;

    & .infoContainer {
      flex: 1;
      & p {
        @include desktop_h5;
      }

      & .macros {
        margin-top: 4px;
        @include text_x_small_regular;
        color: $grayLight;

        & span {
          color: $grayDark;
        }
      }
    }

    & .countersContainer {
      display: flex;
      align-items: center;
      & .counter {
        display: flex;
        align-items: center;
        gap: 5px;
        & span {
          @include text_x_small_bold;
          width: 20px;
          text-align: right;
        }
        & .iconContainer {
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;

          & svg {
            fill: $white;
          }
        }
      }
    }
  }

  & .foodsContainer {
    margin-top: 4px;
    padding-left: 2px;
    padding-right: 2px;
    & .foodDivider {
      height: 2px;
      background: $green;
    }
  }
}
