@import "../../scss/main.scss";

.wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;

  & .contentContainer {
    height: 100vh;
    margin-left: 256px;
    padding: 44px 50px;
    overflow-y: auto;
  }
}
