@import "../../scss/main.scss";

.wrapper {
  padding: 30px;
  border-radius: 24px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);
  -moz-box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);
  box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);
  display: flex;
  flex-direction: row;
  gap: 20px;

  & .userAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: $grayE430;
    position: relative;
    overflow: hidden;
    & svg {
      fill: $grayAf;
    }

    & .avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-right: auto;

    & .body1Title {
      @include text_small_regular;
    }

    & .userName {
      @include desktop_h4;
      line-height: 40px;

      & span {
        font-size: 24px;
        color: $grayLight;
      }
    }

    & .emailContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      & .body1Title {
        @include text_small_regular;
        color: $grayLight;
      }

      & svg {
        width: 20px;
        height: 20px;
      }
    }

    & .headerTitle {
      @include desktop_h5;
      font-weight: 600;
    }
  }

  & .personalInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & .rowContainer {
      display: flex;
      flex-direction: row;
      gap: 15px;
    }

    & .coachCallsInfo {
      padding: 12px 16px;
      border-radius: 12px;
      background: $primaryLight;
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;

      & .infoBlock {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 120px;

        & .title {
          @include text_small_regular;
        }

        & .value {
          @include text_medium_bold;
        }
      }
    }
  }
}
