@import "../../scss/main.scss";

.wrapper {
  position: relative;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .circleContainer {
    position: relative;
    width: 56px;

    height: 47px;

    & .circle {
      transform: rotate(243deg);
    }

    & .iconWrapper {
      position: absolute;
      left: 50%;
      top: 60%;
      transform: translate(-50%, -50%);
    }
  }

  & span {
    @include text_small_bold;
    text-align: center;
  }
}
