@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .rowContainer {
    display: flex;
    gap: 4px;
    align-items: center;
    & .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      border-radius: 10px;

      & svg {
        width: 14px;
        height: 14px;
      }
    }
    & .title {
      @include text_x_small_regular;
      color: $grayDark;
    }
  }

  & .value {
    @include text_small_bold;
  }
}
