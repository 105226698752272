//fonts
$primary-font: "EudoxusSans", "sans-serif";
$secondary-font: "EudoxusSans", "sans-serif";

//colors
$white: #ffffff;
$black: #000000;
$textMain: #495260;
$primary: #612aff;
$primaryLight: #e7deff;

$additional: #dfd4ff;
$grayE4: #e4e4e4;
$grayE450: #e4e4e432;
$grayE425: #e4e4e419;
$grayE430: #e4e4e44d;
$grayVeryLight: #f5f7fa;
$grayAf: #afafaf;
$gray: #bbc9d7;
$grayMediumDark: #d9e0e9;
$grayMedium: #e2e4e7;
$grayDark: #8896aa;
$overlay: #1b1d2146;
$grayLight: #808191;
$deactive: #b2b3bd;
$shadow: #e3e6ec;
$dark: #11142d;
$red: #ff3c3c;
$green: #36d90e;
$bg: rgba(245, 247, 250, 1);
$greenOpacity20: rgba(54, 217, 14, 0.2);
$greenOpacity10: rgba(54, 217, 14, 0.1);
$springGreen: #01e3ad;
$springGreenOpacity20: rgba(1, 227, 173, 0.2);
$blue: #4ac8fb;
$blueOpacity20: rgba(74, 200, 251, 0.2);
$orchid: #e478f5;
$orchidOpacity20: rgba(228, 120, 245, 0.2);
$brown: #c17834;
$brownOpacity20: rgba(193, 120, 52, 0.2);
$orange: #ff754c;
$redOpacity20: #ff295033;
$redOpacity10: #ff29501a;
$orange: #ffa800;
$orangeOpacity10: #ffa8001a;
$orangeOpacity20: rgba(255, 168, 0, 0.2);
