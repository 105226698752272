@import "../../scss/main.scss";

.wrapper {
  max-width: 477px;
  margin-left: auto;
  display: flex;
  flex-direction: column;

  & .title {
    @include text_large_bold;
  }

  & .contentContainer {
    padding: 24px;
    border: 1px solid $shadow;
    border-radius: 20px;
    margin-top: 12px;

    & .header {
      margin-bottom: 35px;
      display: flex;
      gap: 20px;
      justify-content: center;
    }

    & .block {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80px;

      & .consistency {
        width: 24px;
        height: 24px;
        fill: $orange;
      }

      & .streak {
        width: 24px;
        height: 24px;
        fill: $primary;
      }

      & p {
        @include text_small_bold;
        margin-top: 10px;
      }
    }

    & .calendar {
      & .weekDaysNamesContainer {
        display: flex;
        justify-content: space-between;
        margin-top: 13px;

        & .weekName {
          @include text_small_bold;
          color: $grayDark;
          width: 46px;
          text-align: center;
        }
      }

      & .weekContainer {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        flex-wrap: wrap;
        row-gap: 5px;

        & .weekRowContainer {
          display: flex;
          flex: 1;
          position: relative;
          justify-content: space-between;
          & .weekBackground {
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            position: absolute;

            background: $grayVeryLight;
            z-index: -1;
            border-radius: 70px;
          }
        }
      }

      & .dayWrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        & .dayStreakContainer {
          width: 100%;
          height: 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;

          & .percents {
            position: absolute;
            bottom: -10px;
            @include text_x_small_bold;
            color: $grayLight;
          }
          & .dayBackground {
            position: absolute;
            width: 100%;
            height: 24px;
            top: 8px;
            z-index: -1;
            width: 46px;
          }

          & .day {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include text_small_bold;

            & .green {
              width: 6px;
              height: 6px;
              background: $green;
              position: absolute;
              bottom: 4px;
              border-radius: 100px;
            }
          }
        }

        & .inactiveDay {
          color: $grayMedium;
        }

        & .today {
          border-radius: 100px;
          background-color: $primary;
          color: $white;
        }
      }
    }
  }

  & .footerChart {
    display: flex;
    align-items: center;
    column-gap: 15px;
    row-gap: 5px;
    margin-top: 30px;
    flex-wrap: wrap;
  }

  & .loaderContainer {
    width: 477px;
    height: 506px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
