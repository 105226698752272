@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex: 1;

  flex-direction: column;
  gap: 12px;

  & .title {
    @include desktop_h5;
  }

  & .container {
    border: 1px solid $shadow;
    border-radius: 20px;
    padding: 24px;

    & .rowContainer {
      display: flex;
      gap: 2px;
    }

    & .progressContainer {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      position: relative;

      & .title {
        @include text_small_regular;
      }

      & .weight {
        @include text_medium_bold;

        & span {
          @include text_small_regular;
        }
      }

      & .date {
        @include text_x_small_regular;
        color: $grayLight;
        margin-bottom: 20px;
      }

      & .tooltip {
        padding: 4px 10px;
        background: rgba(30, 38, 50, 1);
        border-radius: 10px;
        height: 56px;
        position: relative;
        width: max-content;

        & p {
          @include text_x_small_regular;
          font-size: 10px;
          color: rgba(187, 201, 215, 1);
          text-align: center;
          line-height: 16px;
        }

        & .value {
          color: $white;
          font-weight: 600;
          & span {
            font-weight: 400;

            color: rgba(187, 201, 215, 1);
          }
        }
        & svg {
          position: absolute;
          bottom: -12px;
          transform: translateX(-50%);
          left: 50%;
          fill: rgba(30, 38, 50, 1);
        }
      }

      & .rowProgressContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-direction: row;

        & svg {
          width: 20px;
          height: 20px;
        }
      }

      & .rowContainer {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

        & .weight {
          @include text_large_bold;

          & span {
            @include text_small_regular;
          }
        }
      }
    }
  }
}
