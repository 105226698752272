@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 12px;

  & .title {
    @include desktop_h5;
  }

  & .container {
    padding: 12px 24px 12px 16px;
    border: 1px solid $shadow;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 15px;

    & .value {
      @include text_medium_bold;
    }
  }
}
