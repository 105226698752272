@import "../../scss/main.scss";

.wrapper {
  padding: 30px;
  border-radius: 24px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);
  -moz-box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);
  box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);
  display: flex;
  flex-direction: row;
  gap: 36px;
}
