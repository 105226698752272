@import "../../scss/main.scss";

.wrapper {
  padding: 30px;
  border-radius: 24px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);
  -moz-box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);
  box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);
  gap: 25px;
  & .rowContainer {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  & .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
  }

  & .contentContainer {
    display: flex;
    gap: 25px;
    margin-top: 25px;
    justify-content: space-between;

    & .container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      flex: 1;

      & .sectionsContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    & .rightContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      flex: 1;
      margin-top: 62px;

      & .sectionsContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}
