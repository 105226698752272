@import "vars";

//DESKTOP-HEADING
@mixin desktop_h1 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 90px;
}

@mixin desktop_h2 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 800;
  font-size: 56px;
  line-height: 66px;
}

@mixin desktop_h3 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
}

@mixin desktop_h4 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

@mixin desktop_h5 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

//MOBILE-HEADING
@mixin mobile_h1 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
}

@mixin mobile_h2 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 36px;
}

@mixin mobile_h3 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
}

@mixin mobile_h4 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
}

@mixin mobile_h5 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
//TEXT
@mixin text_x_large_bold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
}

@mixin text_x_large_medium {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
}
@mixin text_x_large_regular {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
}

@mixin text_large_bold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
}

@mixin text_large_medium {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
}

@mixin text_large_regular {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
}

@mixin text_medium_bold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
@mixin text_medium_medium {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
@mixin text_medium_regular {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

@mixin text_small_bold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}
@mixin text_small_medium {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
@mixin text_small_regular {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

@mixin text_x_small_bold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

@mixin text_x_small_medium {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

@mixin text_x_small_regular {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
