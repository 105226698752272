@import "../../scss/styles.scss";

.wrapper {
  position: relative;
  min-width: 375px;
  height: 812px;
  padding: 0;

  & .background {
    position: absolute;
    width: 375px;
    height: 812px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    margin: 0;
  }
  & .contentContainer {
    position: absolute;
    top: 115px;
    left: 16px;
    right: 16px;
    bottom: 0;
    z-index: 9;
    overflow-y: auto;
  }
}
