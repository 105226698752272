@import "../../scss/styles.scss";

.wrapper {
  & .accordion {
    display: flex;
    background: $grayVeryLight;
    padding: 14px 10px;
    border-radius: 15px;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;

    & .iconContainer {
      width: 36px;
      height: 36px;
      border-radius: 100px;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        object-fit: cover;
        width: 20px;
        height: 20px;
        border-radius: 2px;
      }
    }

    & span {
      @include text_medium_bold;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .arrowContainer {
      width: 36px;
      height: 36px;
      border-radius: 100px;
      background: $grayMediumDark;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      & svg {
        width: 14px;
        height: 14px;
        stroke: $black;
      }
    }

    & .arrowContainerOpen {
      background: $primaryLight;

      & svg {
        stroke: $primary;
        transform: rotate(180deg);
      }
    }
  }
}
