@import "../../scss/main.scss";

.wrapper {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: $grayVeryLight;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 12px;
    height: 12px;
    stroke: $dark;
  }
}
