@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  & .title {
    @include desktop_h5;
  }

  & .updatedDate {
    @include text_small_regular;
    color: $grayLight;
    position: absolute;
    right: 0;
    top: 0;
  }

  & .infoTitle {
    @include text_small_regular;
    & span {
      @include text_small_bold;
    }
  }

  & .table {
    border: 1px solid $shadow;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 12px;

    & .tableHeader {
      height: 32px;
      background: $bg;
      display: flex;
      align-items: center;

      & .tableHeaderTitle {
        @include text_small_bold;
        flex: 1;
        text-align: center;
        color: $grayLight;
      }
    }
    & .tableRow {
      height: 44px;
      display: flex;
      align-items: center;
      border-top: 1px solid $bg;
      & .tableRowTitle {
        @include text_small_bold;

        flex: 1;
        text-align: center;

        & svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
