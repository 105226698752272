@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;

  & :hover {
    cursor: pointer;
  }

  & .title {
    @include text_small_regular;
  }

  & .row {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  & .valueTitle {
    @include text_medium_bold;
  }

  & .hiddenTitle {
    @include text_medium_bold;
    color: $grayLight;
  }
}
