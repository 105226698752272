@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-radius: 20px;
  border: 1px solid $shadow;

  & span {
    @include text_small_bold;
  }
}
