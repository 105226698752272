@import "../../../scss/main.scss";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 1520px;
  width: 100%;
}

.wrapper {
  padding-bottom: 30px;
  position: relative;

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include desktop_h4;
    }

    & .buttonsContainer {
      display: flex;
      align-items: center;
      gap: 20px;

      & .scrollLeftButton {
        width: 44px;
        height: 44px;
        background: $grayVeryLight;
        border-radius: 100px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
          width: 16px;
          height: 16px;
          stroke: $black;
          transform: rotate(90deg);
        }
      }

      & .scrollRightButton {
        width: 44px;
        height: 44px;
        background: $grayVeryLight;
        border-radius: 100px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
          width: 16px;
          height: 16px;
          stroke: $black;
          transform: rotate(-90deg);
        }
      }

      & .scrollButtonDisabled {
        opacity: 0.4;
      }
    }
  }

  & .contentContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    overflow-y: auto;
    padding: 20px 30px;
  }
}
