@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 30px;
  width: 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 1382px;

  & .loaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
  }

  & .header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
    margin-left: -8px;
    & .headerArrowButton {
      cursor: pointer;
      width: 32px;
      height: 32px;
      stroke: $black;
    }

    & .headerTitle {
      @include desktop_h5;
      font-weight: 600;
    }
  }

  & .contentContainer {
    background: $white;
    border-radius: 24px;
    margin-top: 30px;
    padding: 30px;

    -webkit-box-shadow: 0px 0px 30px 0px $shadow;
    -moz-box-shadow: 0px 0px 30px 0px $shadow;
    box-shadow: 0px 0px 30px 0px $shadow;

    display: flex;
    flex-direction: column;
    align-items: center;
    & .dateRange {
      display: flex;
      align-items: center;
      gap: 47px;

      & .arrowLeft {
        cursor: pointer;
        width: 32px;
        height: 32px;
        stroke: $black;
        transform: rotate(0deg);
      }

      & .arrowRight {
        cursor: pointer;
        width: 32px;
        height: 32px;
        stroke: $black;
        transform: rotate(180deg);
      }

      & .info {
        & .date {
          @include desktop_h5;
          text-align: center;
        }

        & .title {
          margin-top: 5px;
          @include text_small_bold;
          color: $grayLight;
          text-align: center;
        }

        & .value {
          @include text_large_bold;
          text-align: center;
        }
      }
    }

    & .loaderWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70vh;
    }

    & .table {
      margin-top: 28px;
      width: 100%;
      & .tableHeader {
        display: flex;
        padding-bottom: 15px;

        border-bottom: 1px solid $shadow;

        & .tableHeaderTitle {
          flex: 0.7;
          @include text_small_bold;
          color: $grayLight;
        }
      }

      & .tableValues {
        display: flex;

        padding: 12px 0;
        border-bottom: 1px solid $shadow;
        & .tableValueTitle {
          flex: 0.7;
          @include text_small_regular;
        }

        & .tableValueTitleBold {
          flex: 0.7;
          @include text_small_bold;
        }
      }
    }
  }
}
