@import "../../scss/main.scss";

.paginationContainer {
  margin-top: 30px;
  display: flex;
  gap: 8px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 16px;
  background-color: $grayE450;
  cursor: pointer;
}
.activeWrapper {
  background-color: $primary;
}

.text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  font-family: $secondary-font;
}
.activeText {
  color: $white;
}

.break {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prevBtn {
  display: none;
}

.nextBtn {
  cursor: pointer;
  background-color: $primary;
  width: 90px;
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $white;
  font-family: $secondary-font;
}

.nextText {
  width: 100%;
  height: 100%;
  display: flex;
  color: $white;
  align-items: center;
  justify-content: center;
}

.nextBtnDisabled {
  display: none;
}
