@import "../../scss/main.scss";

.wrapper {
  display: flex;
  gap: 15px;

  & .title {
    @include text_small_bold;

    & span {
      @include text_small_regular;
      color: $grayLight;
      margin-left: 5px;
    }
  }
}
