@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  overflow-y: auto;
  outline: none;
}

.modal {
  height: 100%;
  & .innerContainer {
    position: relative;
    width: 730px;
    margin-top: 50px;
    border-radius: 24px;
    background: $white;
    overflow: hidden;

    & .loaderWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 85vh;
    }

    & .imageWrapper {
      width: 100%;
      height: 440px;
      background: $grayE430;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      & .servingContainer {
        position: absolute;
        z-index: 999999;
        bottom: 20px;
        left: 20px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        & .servingButton {
          padding: 10px 15px;
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;

          & svg {
            width: 20px;
            height: 20px;
            fill: $white;
          }

          & .servingTitle {
            @include text_small_bold;
            color: $white;
          }
        }
      }
      & .headerButtonsContainer {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        gap: 10px;
        & .editButton {
          width: 42px;
          height: 42px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 99;
          cursor: pointer;
          background: rgba(19, 19, 19, 0.5);

          & svg {
            fill: #fff;
          }
        }

        & .deleteButton {
        }
      }

      & .recipeIcon {
        fill: $grayAf;
      }

      & .image {
        width: 100%;
        height: 440px;
        object-fit: cover;
      }
    }

    & .contentContainer {
      background: $white;
      padding: 25px 30px;

      & .title {
        @include desktop_h3;
        font-weight: 600;
      }

      & .category {
        @include text_large_regular;
        color: $textMain;
        margin-top: 5px;
      }
      & .macroSectionTitle {
        @include text_small_regular;
        color: $textMain;
        margin-top: 10px;
        margin-bottom: -10px;
      }

      & .macroContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        & .macroItem {
          display: flex;
          flex-direction: column;

          & .macroValue {
            @include text_x_large_bold;
            line-height: 24px;
          }

          & .macroTitle {
            @include text_small_regular;
            color: $grayDark;
          }
        }
      }

      & .description {
        @include text_large_regular;
        color: $textMain;
        margin-top: 20px;
        word-wrap: break-word;
      }

      & .tagsContainer {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 7px;
      }

      & .sectionTitle {
        margin-top: 20px;
        @include text_x_large_bold;
      }

      & .ingredientsList {
        margin-top: 20px;
        & .ingredientsDevider {
          margin-top: 8px;
          margin-bottom: 8px;
          height: 1px;
          background: #f5f7fa;
        }
      }

      & .methodList {
        margin-top: 20px;
        & .methodDevider {
          margin-top: 8px;
          margin-bottom: 8px;
          height: 1px;
          background: #f5f7fa;
        }
        & .methodItem {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 14px;

          & .iconContainer {
            min-width: 24px;
            min-height: 24px;
            border-radius: 100px;
            background: rgba(97, 42, 255, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;

            & span {
              @include text_x_small_bold;
              color: $primary;
            }
          }

          & .description {
            margin: 0;
            flex: 1;
            overflow: hidden;
            @include text_medium_regular;
            word-wrap: break-word;
            color: $textMain;
          }
        }
      }
    }
  }
}
