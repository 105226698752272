@import "../../scss/main.scss";

.wrapper {
  & h6 {
    @include desktop_h5;
    margin-bottom: 10px;
  }

  & .contentContainer {
    display: flex;
    align-items: center;
    gap: 15px;

    & .iconContainer {
      display: flex;
      width: 52px;
      height: 52px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      background: $orangeOpacity10;
      & svg {
        width: 26px;
        height: 26px;
        fill: $orange;
      }
    }

    & p {
      @include text_small_bold;
    }
  }
}
