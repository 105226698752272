@import "../../scss/main.scss";

.wrapper {
  width: 52px;
  height: 52px;
  border-radius: 8px;
  background-color: $grayMedium;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    fill: $grayDark;
    width: 24px;
    height: 24px;
  }

  & img {
    width: 52px;
    height: 52px;
    border-radius: 8px;
  }
}
