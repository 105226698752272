@import "../../scss/main.scss";

.wrapper {
  display: flex;
  gap: 10px;
  padding: 12px 24px;
  border: 1px solid $shadow;
  border-radius: 20px;
  justify-content: space-between;
}
