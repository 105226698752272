@import "../../scss/styles.scss";

.drag-drop-list {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    font-size: 14px;
    // color: $light-gray-color-40;
    line-height: 20px;
  }
}
